.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.avenir-light {
  font-family:Avenir, "Century Gothic", sans-serif; 
  font-weight:100; 
  font-style:normal;
}

.avenir-black-oblique {
  font-family:Avenir, "Century Gothic", sans-serif; 
  font-weight:600; 
  font-style:italic; 
}

.produkt-thin {
  font-family:Produkt, serif; 
  font-weight:100; 
  font-style:normal;
}

/* Global CSS for Typography */

/* Headings with font family */
h1, h2, h3, h4, h5, h6, p, ul, li, .fontTheme {
  font-family: "Nunito Sans", sans-serif;
  margin: 0 0 16px;
}

h1 {
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.167;
  letter-spacing: -0.01562em;
}

h2 {
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 1.2;
  letter-spacing: -0.00833em;
}

h3 {
  
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.167;
  letter-spacing: 0em;
}

h4 {
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 1.235;
  letter-spacing: 0.00735em;
}

h5 {
  font-weight: 400;
  font-size: 1.15rem;
  line-height: 1.334;
  letter-spacing: 0em;
}

h6, p, ul, li{
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

/* Media Queries */
@media (max-width: 750px) {
  h2 {
      font-size: 2rem; /* Font size for small screens */
  }
  h3 {
      font-size: 1.75rem; /* Font size for small screens */
  }
  h4 {
      font-size: 1.5rem; /* Font size for small screens */
  }
  h5 {
      font-size: 1.15rem; /* Font size for small screens */
  }
  h6, p {
      font-size: 1rem; /* Font size for small screens */
  }
}

@media (max-width: 600px) {
  h1 {
      font-size: 2.5rem; /* Font size for small screens */
  }
  h2 {
      font-size: 1.75rem; /* Font size for small screens */
  }
}

@media (max-width: 500px) {
  h3 {
      font-size: 1.6rem; /* Font size for small screens */
  }
  h4 {
      font-size: 1.25rem; /* Font size for small screens */
  }
  h5 {
      font-size: 1rem; /* Font size for small screens */
  }
  h6, p {
      font-size: 0.9rem; /* Font size for small screens */
  }
}

@media (max-width: 400px) {
  h1 {
      font-size: 2.25rem; /* Font size for medium screens */
  }
  h2 {
      font-size: 1.5rem; /* Font size for medium screens */
  }
  h3 {
      font-size: 1.3rem; /* Font size for small screens */
  }
  h4 {
      font-size: 1.15rem; /* Font size for small screens */
  }
  h5 {
      font-size: .95rem; /* Font size for small screens */
  }
  h6, p {
    font-size: .85rem; /* Font size for small screens */
}
}

.react-swipeable-view-container {
  /* display: block !important */
  max-width: 100vw; 
}
