.sans-serif-light {
  font-family:"Nunito Sans", sans-serif;
  font-weight:300; 
  font-style:normal;
}

.sans-serif-black-italic {
  font-family:"Nunito Sans", sans-serif; 
  font-weight:800; 
  font-style:italic; 
}

.produkt-thin {
  font-family:Produkt, serif; 
  font-weight:100; 
  font-style:normal;
}

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

